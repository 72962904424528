import React from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"
import { Accordion } from "devextreme-react"
import { Item as AccordionItem } from "devextreme-react/accordion"

import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"

import buildCircle from "../images/build_circle.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import SequenceDiagram from "../components/sequence-diagram"

const HowItWorksW = styled.div``

const HowItWorks = () => {
  if (typeof window === `undefined`) return null
  return (
    <Layout>
      <SEO title="How It Works" />
      <PageTitle title="How It Works" subTitle="" icon={buildCircle} />
      <HowItWorksW>
        <Container>
          <Accordion
            collapsible={true}
            defaultSelectedItem={null}
            multiple={true}
          >
            <AccordionItem title="Sending Vendors To Concur">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={190}
                height={700}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={700}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW9"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Send vendors to Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get transformed vendors",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title: "Get new or modified vendors",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Response (new or modified vendors)",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform vendors to Concur format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed vendors)",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Send vendors to Concur",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Response (api call result)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Response (operation result)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 3,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 4,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 1,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Importing Accounts Payable Invoices From Concur Into Sage">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={270}
                height={900}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={900}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW10"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Fetch accounts payable invoices from Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get new accounts payable invoices",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title:
                      "Response (new accounts payable invoices in flat file format)",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Transform flat files",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform accounts payable invoices to Sage format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed accounts payable invoices)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Response (transformation results)",
                  })
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title:
                      "Import selected accounts payable invoices into Sage",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title:
                      "Import selected accounts payable invoices into Sage",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 11,
                    number: 10,
                    displayOrder: 18,
                    title:
                      "Import selected accounts payable invoices into Sage",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 12,
                    number: 11,
                    displayOrder: 20,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 13,
                    number: 12,
                    displayOrder: 22,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 14,
                    number: 13,
                    displayOrder: 24,
                    title: "Response (importing results)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 1,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 2,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 0,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                  addProcess({
                    location: 3,
                    top: 10,
                    height: 1,
                    displayOrder: 17,
                  })
                  addProcess({
                    location: 4,
                    top: 11,
                    height: 1,
                    displayOrder: 19,
                  })
                  addProcess({
                    location: 3,
                    top: 12,
                    height: 1,
                    displayOrder: 21,
                  })
                  addProcess({
                    location: 2,
                    top: 13,
                    height: 1,
                    displayOrder: 23,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Importing Accounts Payable Payments From Concur Into Sage">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={270}
                height={900}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={900}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW1"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Fetch accounts payable payments from Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get new accounts payable payments",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title:
                      "Response (new accounts payable payments in flat file format)",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Transform flat files",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform accounts payable payments to Sage format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed accounts payable payments)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Response (transformation results)",
                  })
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title:
                      "Import selected accounts payable payments into Sage",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title:
                      "Import selected accounts payable payments into Sage",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 11,
                    number: 10,
                    displayOrder: 18,
                    title:
                      "Import selected accounts payable payments into Sage",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 12,
                    number: 11,
                    displayOrder: 20,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 13,
                    number: 12,
                    displayOrder: 22,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 14,
                    number: 13,
                    displayOrder: 24,
                    title: "Response (importing results)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 1,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 2,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 0,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                  addProcess({
                    location: 3,
                    top: 10,
                    height: 1,
                    displayOrder: 17,
                  })
                  addProcess({
                    location: 4,
                    top: 11,
                    height: 1,
                    displayOrder: 19,
                  })
                  addProcess({
                    location: 3,
                    top: 12,
                    height: 1,
                    displayOrder: 21,
                  })
                  addProcess({
                    location: 2,
                    top: 13,
                    height: 1,
                    displayOrder: 23,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Sending Purchase Orders To Concur">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={190}
                height={700}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={700}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW8"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Send purchase orders to Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get transformed purchase orders",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title: "Get new or modified purchase orders",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Response (new or modified purchase orders)",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform purchase orders to Concur format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed purchase orders)",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Send purchase orders to Concur",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Response (api call result)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Response (operation result)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 3,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 4,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 1,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Sending Purchase Order Receipts To Concur">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={190}
                height={700}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={700}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW6"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Send purchase order receipts to Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get transformed purchase order receipts",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title: "Get new or modified purchase order receipts",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Response (new or modified purchase order receipts)",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform purchase order receipts to Concur format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed purchase order receipts)",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Send purchase order receipts to Concur",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Response (api call result)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Response (operation result)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 3,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 4,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 1,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Importing Purchase Order Invoices From Concur Into Sage">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={270}
                height={900}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={900}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW4"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Fetch purchase order invoices from Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get new purchase order invoices",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title:
                      "Response (new purchase order invoices in flat file format)",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Transform flat files",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform purchase order invoices to Sage format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed purchase order invoices)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Response (transformation results)",
                  })
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Import selected purchase order invoices into Sage",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Import selected purchase order invoices into Sage",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 11,
                    number: 10,
                    displayOrder: 18,
                    title: "Import selected purchase order invoices into Sage",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 12,
                    number: 11,
                    displayOrder: 20,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 13,
                    number: 12,
                    displayOrder: 22,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 14,
                    number: 13,
                    displayOrder: 24,
                    title: "Response (importing results)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 1,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 2,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 0,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                  addProcess({
                    location: 3,
                    top: 10,
                    height: 1,
                    displayOrder: 17,
                  })
                  addProcess({
                    location: 4,
                    top: 11,
                    height: 1,
                    displayOrder: 19,
                  })
                  addProcess({
                    location: 3,
                    top: 12,
                    height: 1,
                    displayOrder: 21,
                  })
                  addProcess({
                    location: 2,
                    top: 13,
                    height: 1,
                    displayOrder: 23,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Importing Purchase Order Payments From Concur Into Sage">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={270}
                height={900}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={900}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW5"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Fetch purchase order payments from Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get new purchase order payments",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title:
                      "Response (new purchase order payments in flat file format)",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Transform flat files",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform purchase order payments to Sage format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed purchase order payments)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Response (transformation results)",
                  })
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Import selected purchase order payments into Sage",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Import selected purchase order payments into Sage",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 11,
                    number: 10,
                    displayOrder: 18,
                    title: "Import selected purchase order payments into Sage",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 12,
                    number: 11,
                    displayOrder: 20,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 13,
                    number: 12,
                    displayOrder: 22,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 14,
                    number: 13,
                    displayOrder: 24,
                    title: "Response (importing results)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 1,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 2,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 0,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                  addProcess({
                    location: 3,
                    top: 10,
                    height: 1,
                    displayOrder: 17,
                  })
                  addProcess({
                    location: 4,
                    top: 11,
                    height: 1,
                    displayOrder: 19,
                  })
                  addProcess({
                    location: 3,
                    top: 12,
                    height: 1,
                    displayOrder: 21,
                  })
                  addProcess({
                    location: 2,
                    top: 13,
                    height: 1,
                    displayOrder: 23,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Importing Expenses From Concur Into Sage">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={270}
                height={900}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={900}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW3"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Fetch expenses from Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get new expenses",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title: "Response (new expenses in flat file format)",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Transform flat files",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform expenses to Sage format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed expenses)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Response (transformation results)",
                  })
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Import selected expenses into Sage",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Import selected expenses into Sage",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 11,
                    number: 10,
                    displayOrder: 18,
                    title: "Import selected expenses into Sage",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 12,
                    number: 11,
                    displayOrder: 20,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 13,
                    number: 12,
                    displayOrder: 22,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 14,
                    number: 13,
                    displayOrder: 24,
                    title: "Response (importing results)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 1,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 2,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 0,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                  addProcess({
                    location: 3,
                    top: 10,
                    height: 1,
                    displayOrder: 17,
                  })
                  addProcess({
                    location: 4,
                    top: 11,
                    height: 1,
                    displayOrder: 19,
                  })
                  addProcess({
                    location: 3,
                    top: 12,
                    height: 1,
                    displayOrder: 21,
                  })
                  addProcess({
                    location: 2,
                    top: 13,
                    height: 1,
                    displayOrder: 23,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Importing Expense Payments From Concur Into Sage">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={270}
                height={900}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={900}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW2"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Fetch expense payments from Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get new expense payments",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title:
                      "Response (new expense payments in flat file format)",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Transform flat files",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform expense payments to Sage format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed expense payments)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Response (transformation results)",
                  })
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Import selected expense payments into Sage",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Import selected expense payments into Sage",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 11,
                    number: 10,
                    displayOrder: 18,
                    title: "Import selected expense payments into Sage",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 12,
                    number: 11,
                    displayOrder: 20,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 13,
                    number: 12,
                    displayOrder: 22,
                    title: "Response (importing results)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 14,
                    number: 13,
                    displayOrder: 24,
                    title: "Response (importing results)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 1,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 2,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 0,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                  addProcess({
                    location: 3,
                    top: 10,
                    height: 1,
                    displayOrder: 17,
                  })
                  addProcess({
                    location: 4,
                    top: 11,
                    height: 1,
                    displayOrder: 19,
                  })
                  addProcess({
                    location: 3,
                    top: 12,
                    height: 1,
                    displayOrder: 21,
                  })
                  addProcess({
                    location: 2,
                    top: 13,
                    height: 1,
                    displayOrder: 23,
                  })
                }}
              />
            </AccordionItem>
            <AccordionItem title="Sending List Items To Concur">
              <SequenceDiagram
                arrowSize={7}
                footerHeight={190}
                height={700}
                pathSpace={50}
                topPadding={40}
                rectangleHeight={50}
                transitionDuration={500}
                viewBoxHeight={700}
                viewBoxWidth={1000}
                width={1000}
                wrapperId="svgW9"
                sections={[100, 300, 500, 700, 900]}
                slices={[
                  { title: "Cloud Servers", position: 200 },
                  { title: "Client Servers", position: 600 },
                ]}
                canvasTopPadding={20}
                draw={({ addArrow, addProcess }) => {
                  addArrow({
                    from: 0,
                    to: 2,
                    top: 1,
                    number: 1,
                    displayOrder: 1,
                    title: "Send list-items to Concur",
                  })
                  addArrow({
                    from: 2,
                    to: 3,
                    top: 2,
                    number: 2,
                    displayOrder: 3,
                    title: "Get transformed list-items",
                  })
                  addArrow({
                    from: 3,
                    to: 4,
                    top: 3,
                    number: 3,
                    displayOrder: 5,
                    title: "Get new or modified list-items",
                  })
                  addArrow({
                    from: 4,
                    to: 3,
                    top: 4,
                    number: 4,
                    displayOrder: 7,
                    title: "Response (new or modified list-items)",
                  })
                  addArrow({
                    from: 3,
                    to: 3,
                    top: 5,
                    number: 5,
                    displayOrder: 8,
                    title: "Transform list-items to Concur format",
                  })
                  addArrow({
                    from: 3,
                    to: 2,
                    top: 7,
                    number: 6,
                    displayOrder: 10,
                    title: "Response (transformed list-items)",
                  })
                  addArrow({
                    from: 2,
                    to: 1,
                    top: 8,
                    number: 7,
                    displayOrder: 12,
                    title: "Send list-items to Concur",
                  })
                  addArrow({
                    from: 1,
                    to: 2,
                    top: 9,
                    number: 8,
                    displayOrder: 14,
                    title: "Response (api call result)",
                  })
                  addArrow({
                    from: 2,
                    to: 0,
                    top: 10,
                    number: 9,
                    displayOrder: 16,
                    title: "Response (operation result)",
                  })

                  addProcess({
                    location: 2,
                    top: 1,
                    height: 1,
                    displayOrder: 2,
                  })
                  addProcess({
                    location: 3,
                    top: 2,
                    height: 1,
                    displayOrder: 4,
                  })
                  addProcess({
                    location: 4,
                    top: 3,
                    height: 1,
                    displayOrder: 6,
                  })
                  addProcess({
                    location: 3,
                    top: 5,
                    height: 1,
                    displayOrder: 9,
                  })
                  addProcess({
                    location: 2,
                    top: 7,
                    height: 1,
                    displayOrder: 11,
                  })
                  addProcess({
                    location: 1,
                    top: 8,
                    height: 1,
                    displayOrder: 13,
                  })
                  addProcess({
                    location: 2,
                    top: 9,
                    height: 1,
                    displayOrder: 15,
                  })
                }}
              />
            </AccordionItem>
          </Accordion>
        </Container>
      </HowItWorksW>
    </Layout>
  )
}

export default HowItWorks
