import React, { useEffect } from "react"
import { select, line } from "d3"

const SequenceChart = ({
  wrapperId = "svgW1",
  arrowSize = 5,
  height = 900,
  pathSpace = 50,
  rectangleHeight = 50,
  topPadding = 40,
  transitionDuration = 300,
  viewBoxHeight = 900,
  viewBoxWidth = 1000,
  width = "100%",
  sections = [100, 300, 500, 700, 900],
  slices = [
    { title: "Cloud Servers", position: 200 },
    { title: "Client Servers", position: 600 },
  ],
  canvasTopPadding = 20,
  footerHeight = 0,
  primaryColor = "rgb(8,31,59)",
  secondaryColor = "rgb(43 120 29)",
  draw,
}) => {
  let footer
  const addMarker = parent => {
    const addDefs = () => parent.append("defs")
    const addRightMarker = parent => {
      parent
        .append("marker")
        .attr("id", `${wrapperId}_rightArrow`)
        .attr("viewBox", [0, 0, arrowSize * 2, arrowSize * 2])
        .attr("refX", arrowSize)
        .attr("refY", arrowSize)
        .attr("markerWidth", arrowSize * 2)
        .attr("markerHeight", arrowSize * 2)
        .attr("orient", "auto-start-reverse")
        .append("path")
        .attr(
          "d",
          line()([
            [0, 0],
            [0, arrowSize * 2],
            [arrowSize * 2, arrowSize],
          ])
        )
        .attr("stroke", primaryColor)
    }
    const addLeftMarker = parent => {
      parent
        .append("marker")
        .attr("id", "leftArrow")
        .attr("viewBox", [0, 0, 10, 10])
        .attr("refX", 5)
        .attr("refY", 5)
        .attr("markerWidth", 10)
        .attr("markerHeight", 10)
        .attr("orient", "auto-start-reverse")
        .append("path")
        .attr(
          "d",
          line()([
            [10, 0],
            [0, 5],
            [10, 10],
          ])
        )
        .attr("stroke", primaryColor)
    }
    const defs = addDefs()
    addRightMarker(defs)
    addLeftMarker(defs)
  }
  const addSvg = () =>
    select(`#${wrapperId}`)
      .append("svg")
      .lower()
      .attr("viewBox", `0 0 ${viewBoxWidth} ${viewBoxHeight + footerHeight}`)

  const addTimeline = parent =>
    parent
      .selectAll(null)
      .data(sections)
      .enter()
      .append("path")
      .attr("d", d => `M ${d} ${50 + topPadding} V ${50 + topPadding}`)
      .transition()
      .delay((d, i) => i * 100)
      .duration(transitionDuration)
      .attr("d", d => `M ${d} ${50 + topPadding} V ${height}`)
      .attr("stroke", primaryColor)

  const addSectionRectangles = parent => {
    const rectangleGroups = parent
      .selectAll(null)
      .data(sections)
      .enter()
      .append("g")
      .attr("transform", d => `translate(${d - 75}, ${topPadding})`)
    rectangleGroups
      .append("rect")
      .attr("width", 150)
      .attr("height", rectangleHeight)
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("fill", "transparent")
      .attr("stroke", primaryColor)
    rectangleGroups
      .data(["Actor", "Concur", "DataMap", "Connectors", "Sage"])
      .append("text")
      .attr("x", 75)
      .attr("y", 30)
      .attr("fill", primaryColor)
      .attr("text-anchor", "middle")
      .text(d => d)
  }

  const addDrawingCanvas = parent =>
    parent
      .append("g")
      .attr(
        "transform",
        `translate(0,${topPadding + rectangleHeight + canvasTopPadding})`
      )
  const addArrow = parent => ({
    from,
    to,
    top = 1,
    number,
    displayOrder,
    title,
  }) => {
    let end, labelX, labelY
    const direction = from === to ? "self" : from > to ? "left" : "right"
    const start = `M ${sections[from]} ${top * pathSpace} H ${sections[from]}`
    end = `M ${sections[from]} ${top * pathSpace} H ${
      direction === "left" ? sections[to] + arrowSize : sections[to] - arrowSize
    }`
    labelX = direction === "left" ? sections[from] - 100 : sections[from] + 100
    labelY = top * pathSpace
    if (direction === "self") {
      end = `M ${sections[from]} ${top * pathSpace} H ${sections[to] +
        50} V ${top * pathSpace + pathSpace} H ${sections[to] + arrowSize}`
      labelX = sections[from] + 50
      labelY = top * pathSpace + 25
    }
    const delay = displayOrder * transitionDuration + displayOrder * 100
    const group = parent.append("g")

    group
      .append("path")
      .attr("stroke", primaryColor)
      .attr("fill", "transparent")
      .attr("d", () => start)
      .transition()
      .duration(transitionDuration)
      .delay(delay)
      .attr("d", () => end)
      .attr("marker-end", `url(#${wrapperId}_rightArrow)`)
    const circleW = group
      .append("g")
      .on("mouseover", () => {
        if (title) {
          select(`#${wrapperId}_svgTooltipW`).style("display", "block")
          select(`#${wrapperId}_svgTooltip`)
            .transition()
            .duration(transitionDuration)
            .style("opacity", 1)
            .text(title)
        }
      })
      .on("mouseout", () => {
        select(`#${wrapperId}_svgTooltipW`).style("display", "none")
        select(`#${wrapperId}_svgTooltip`)
          .transition()
          .duration(transitionDuration)
          .style("opacity", 0)
      })
    footer
      .append("text")
      .attr("y", number * 20)
      .text(`${number}-${number < 10 ? " " : ""} ${title}`)
      .attr("x", width)
      .transition()
      .delay(delay)
      .duration(transitionDuration)
      .attr("x", 100)
      .attr("fill", primaryColor)

    circleW
      .append("circle")
      .attr("fill", primaryColor)
      .attr("cx", labelX)
      .attr("cy", labelY)
      .transition()
      .delay(delay)
      .duration(transitionDuration)
      .attr("r", 15)

    circleW
      .append("text")
      .attr("x", labelX)
      .attr("y", labelY + 5)
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .transition()
      .delay(delay)
      .duration(transitionDuration)
      .text(() => number)

    return group
  }
  const addProcess = parent => ({ location, top, height, displayOrder }) => {
    const delay = displayOrder * transitionDuration + displayOrder * 100
    parent
      .append("rect")
      .attr("x", sections[location] - 10)
      .attr("y", top * pathSpace + 10)
      .attr("height", 0)
      .attr("width", 20)
      .style("fill", secondaryColor)
      .attr("rx", "0.5%")
      .attr("ry", "0.5%")
      .transition()
      .delay(delay)
      .duration(transitionDuration)
      .attr("height", height * pathSpace - 20)
  }

  const addSlices = parent => {
    const groups = parent
      .selectAll(null)
      .data(slices)
      .enter()
      .append("g")
    groups
      .append("path")
      .style("stroke-dasharray", "7, 4")
      .style("opacity", 0.5)
      .attr("stroke", primaryColor)
      .attr("d", d => `M ${d.position} 0 V ${height}`)
    groups
      .append("text")
      .attr("x", d => d.position + 200)
      .attr("y", 20)
      .attr("fill", primaryColor)
      .attr("text-anchor", "middle")
      .text(d => d.title)
  }

  const addFooter = parent => {
    footer = parent.append("g").attr("transform", `translate(0, ${height})`)
  }

  useEffect(() => {
    const svg = addSvg()
    addMarker(svg)
    addTimeline(svg)
    addSectionRectangles(svg)
    addSlices(svg)
    addFooter(svg)
    const canvas = addDrawingCanvas(svg)
    draw({ canvas, addArrow: addArrow(canvas), addProcess: addProcess(canvas) })
    return () => {
      select(`#${wrapperId}`)
        .select("svg")
        .remove()
    }
  })
  return (
    <div id={wrapperId} style={{ position: "relative", textAlign: "center" }}>
      <div
        id={`${wrapperId}_svgTooltipW`}
        style={{
          position: "absolute",
          top: 150,
          width: "100%",
          textAlign: "center",
          display: "none",
        }}
      >
        <div
          id={`${wrapperId}_svgTooltip`}
          style={{
            display: "inline",
            opacity: 0,
            textAlign: "center",
            backgroundColor: primaryColor,
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "0.5rem",
          }}
        />
      </div>
    </div>
  )
}

export default SequenceChart
